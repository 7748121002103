import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import ExamGroupsAPI from '../../../common/api/exam-groups';
import { ToastStatus } from '../../../common/enums/toast';
import { connectToasts } from '../../../common/redux/connects';
import { ConfirmPopover } from '../../../components/common/popover';


const initExamGroup = { name: '', description: '', id: 'new-exam-group' };

function AdminExamGroupsList({ history, addToastAction }) {
    const [examGroups, setExamGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newExamGroup, setNewExamGroup] = useState(initExamGroup);

    // Confirm Popover
    const [confirmTarget, setConfirmTarget] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [currentExamGroupId, setCurrentExamGroupId] = useState(null);

    useEffect(() => {
        setLoading(true);
        ExamGroupsAPI.getAllExamGroups().then(eGroups => {
            setExamGroups(eGroups);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }, []);

    const onRequestConfirmToRemove = (event, examGroup) => {
        setCurrentExamGroupId(examGroup.id);
        setConfirmTarget(event.target);
        setConfirmVisible(true);
    };

    const onDeleteExamGroup = () => {
        setConfirmVisible(false);
        setLoading(true);
        ExamGroupsAPI.deleteExamGroup(currentExamGroupId).then(res => {
            setLoading(false);
            addToastAction('Congratulation', 'You have successfully updated this Exam Group.', ToastStatus.Success);
            setExamGroups(examGroups.filter(examGroup => examGroup.id !== currentExamGroupId));
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };

    const onChangeExamGroup = (eGroup, key, value) => {
        setExamGroups(examGroups.map(examGroup => {
            if (examGroup.id !== eGroup.id) {
                return examGroup;
            } else {
                return { ...examGroup, [key]: value };
            }
        }));
    };

    const onSubmitExamGroup = (eGroup) => {
        setLoading(true);
        ExamGroupsAPI.updateExamGroup(eGroup).then(res => {
            setLoading(false);
            addToastAction('Congratulation', 'You have successfully updated this Exam Group.', ToastStatus.Success);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };

    const onChangeNewExamGroup = (value, key) => {
        setNewExamGroup({ ...newExamGroup, [key]: value });
    }

    const onCreateNewExamGroup = () => {
        setLoading(true);
        ExamGroupsAPI.createExamGroup(newExamGroup).then(res => {
            setLoading(false);
            setExamGroups(examGroups.concat([{ ...newExamGroup, id: res.id }]));
            setNewExamGroup(initExamGroup);
            addToastAction('Congratulation', 'You have successfully created a Exam Group.', ToastStatus.Success);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }

    return (<Container className='pt-4'>
        <Row>
            <Col>
                <Button variant='link' onClick={() => history.goBack()}>
                    <span className='h6'>Back</span>
                </Button>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                <h1 className='text-primary text-center'>Exam Group List</h1>
            </Col>
        </Row>
        <Row>
            <Col sm={12} className='d-flex py-3 border-bottom'>
                <div className='ml-2 mt-auto' style={{ flex: 'auto' }}>
                    <label htmlFor={`fcontrol-${newExamGroup.id}`}><b>Name</b></label>
                    <InputGroup>
                        <FormControl id={`fcontrol-${newExamGroup.id}-name`} value={newExamGroup.name} onChange={e => onChangeNewExamGroup(e.target.value, 'name')} />
                    </InputGroup>
                    <label htmlFor={`fcontrol-${newExamGroup.id}`}><b>Description</b></label>
                    <InputGroup>
                        <FormControl as='textarea' id={`fcontrol-${newExamGroup.id}-description`} value={newExamGroup.description} onChange={e => onChangeNewExamGroup(e.target.value, 'description')} />
                    </InputGroup>
                </div>
                <div className='d-flex flex-column justify-content-end ml-2 '>
                    <Button variant='success' onClick={onCreateNewExamGroup}>Create</Button>
                </div>
            </Col>
            {examGroups.map((examGroup, ebi) => (<Col key={ebi} sm={12} md={6} className='d-flex py-3 border-bottom'>
                <div className='ml-2 mt-auto' style={{ flex: 'auto' }}>
                    <label htmlFor={`fcontrol-${examGroup.id}-name`}><b>Name</b></label>
                    <InputGroup>
                        <FormControl id={`fcontrol-${examGroup.id}`} value={examGroup.name} onChange={e => onChangeExamGroup(examGroup, 'name', e.target.value)} />
                    </InputGroup>
                    <label htmlFor={`fcontrol-${examGroup.id}-description`}><b>Description</b></label>
                    <InputGroup>
                        <FormControl as='textarea' id={`fcontrol-${examGroup.id}-description`} value={examGroup.description} onChange={e => onChangeExamGroup(examGroup, 'description', e.target.value)} />
                    </InputGroup>
                </div>
                <div className='d-flex flex-column justify-content-between ml-2 '>
                    <FontAwesomeIcon className='cursor-pointer text-danger ml-auto' size='lg' icon={faTimes} onClick={(e) => onRequestConfirmToRemove(e, examGroup)} />
                    <Button onClick={() => onSubmitExamGroup(examGroup)}>Submit</Button>
                </div>
            </Col>))}
        </Row>
        <ConfirmPopover visible={confirmVisible} target={confirmTarget} placement='left' setVisibility={setConfirmVisible} label='Are you sure to delete Exam Group?' onConfirm={onDeleteExamGroup} />
    </Container>);
};

AdminExamGroupsList.propTypes = {};
AdminExamGroupsList.defaultProps = {};

export default withRouter(connectToasts(AdminExamGroupsList));
