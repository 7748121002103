import { UITheme } from '../enums/theme';

// UI Theme
export const defaultTheme = UITheme.Light;

// Const Names
export const ConstantNames = {
    AccessToken: 'access-token',
    UITheme: 'ui-theme'
}

// API Integration
const DevServerURL = 'http://localhost:3030';
const ProdServerURL = 'http://178.62.103.65:3030';
const ServerURL = process.env.NODE_ENV === 'production' ? ProdServerURL : DevServerURL;

export const APIServerURL = `${ServerURL}/api`;
export const AssetsServerURL = `${ServerURL}`;
