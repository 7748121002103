import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const examBodiesBaseURL = `${APIServerURL}/exam-bodies`;
const ExamBodiesAPI =  {
    getAllExamBodies: () => {
        const url = `${examBodiesBaseURL}`;
        return axios.get(url);
    },
    getExamBodyById: (examBodyId) => {
        const url = `${examBodiesBaseURL}/${examBodyId}`;
        return axios.get(url);
    },
    createExamBody: (examBody) => {
        const url = `${examBodiesBaseURL}`;
        return axios.post(url, examBody);
    },
    updateExamBody: (examBody) => {
        const url = `${examBodiesBaseURL}/${examBody.id}`;
        return axios.put(url, { ...examBody, id: null });
    },
    deleteExamBody: (examBodyId) => {
        const url = `${examBodiesBaseURL}/${examBodyId}`;
        return axios.delete(url);
    }
}

export default ExamBodiesAPI;
