import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const subTopicsBaseURL = `${APIServerURL}/sub-topics`;
const SubTopicsAPI =  {
    getAllSubTopics: () => {
        const url = `${subTopicsBaseURL}`;
        return axios.get(url)
    },
    getSubTopics: (topicId) => {
        const url = `${subTopicsBaseURL}/${topicId}`;
        return axios.get(url)
    },
    registerSubTopic: (topicId, subTopic) => {
        const url = `${subTopicsBaseURL}/${topicId}`;
        return axios.post(url, subTopic)
    },
    updateSubTopic: (subTopic) => {
        const url = `${subTopicsBaseURL}/${subTopic.id}`;
        return axios.put(url, { ...subTopic, id: null })
    },
    deleteSubTopic: (subTopicId) => {
        const url = `${subTopicsBaseURL}/${subTopicId}`;
        return axios.delete(url);
    }
}

export default SubTopicsAPI;
