import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import ExamBodyAPI from '../../../common/api/exam-bodies';
import UploadAPI from '../../../common/api/upload';
import { AssetsServerURL } from '../../../common/constants/default-values';
import { ToastStatus } from '../../../common/enums/toast';
import { connectToasts } from '../../../common/redux/connects';
import { ConfirmPopover } from '../../../components/common/popover';


const initExamBody = { logoUrl: `${AssetsServerURL}/images/upload.png`, name: '', id: 'new-exam-body' };

function AdminExamBodiesList({ history, addToastAction }) {
    const [examBodies, setExamBodies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newExamBody, setNewExamBody] = useState(initExamBody);

    // Confirm Popover
    const [confirmTarget, setConfirmTarget] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [currentExamBodyId, setCurrentExamBodyId] = useState(null);

    // File Input
    const fileInput = useRef(null);

    useEffect(() => {
        setLoading(true);
        ExamBodyAPI.getAllExamBodies().then(eBodies => {
            setExamBodies(eBodies);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }, []);

    const onRequestConfirmToRemove = (event, examBody) => {
        setCurrentExamBodyId(examBody.id);
        setConfirmTarget(event.target);
        setConfirmVisible(true);
    };

    const onDeleteExamBody = () => {
        setConfirmVisible(false);
        setLoading(true);
        ExamBodyAPI.deleteExamBody(currentExamBodyId).then(res => {
            setLoading(false);
            addToastAction('Congratulation', 'You have successfully updated this Exam Body.', ToastStatus.Success);
            setExamBodies(examBodies.filter(examBody => examBody.id !== currentExamBodyId));
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };

    const onChangeExamBody = (eBody, key, value) => {
        setExamBodies(examBodies.map(examBody => {
            if (examBody.id !== eBody.id) {
                return examBody;
            } else {
                return { ...examBody, [key]: value };
            }
        }));
    };

    const onClickExamBodyLogo = (eBody) => {
        setCurrentExamBodyId(eBody.id);
        fileInput.current.click();
    };

    const onFileChanged = async e => {
        const { files } = e.target;
        if (files.length > 0) {
            const file = e.target.files[0];
            const fileName = await UploadAPI.uploadFile(file);
            if (currentExamBodyId === 'new-exam-body') {
                setNewExamBody({ ...newExamBody, logoUrl: fileName });
            } else {
                setExamBodies(examBodies.map(examBody => {
                    if (examBody.id !== currentExamBodyId) {
                        return examBody;
                    } else {
                        return { ...examBody, logoUrl: fileName };
                    }
                }));
            }
        }
    }

    const onSubmitExamBody = (eBody) => {
        setLoading(true);
        ExamBodyAPI.updateExamBody(eBody).then(res => {
            setLoading(false);
            addToastAction('Congratulation', 'You have successfully updated this Exam Body.', ToastStatus.Success);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };

    const onChangeNewExamBody = (value, key) => {
        setNewExamBody({ ...newExamBody, [key]: value });
    }

    const onCreateNewExamBody = () => {
        setLoading(true);
        ExamBodyAPI.createExamBody(newExamBody).then(res => {
            setLoading(false);
            setExamBodies(examBodies.concat([{ ...newExamBody, id: res.id }]));
            setNewExamBody(initExamBody);
            addToastAction('Congratulation', 'You have successfully created a Exam Body.', ToastStatus.Success);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }

    return (<Container className='pt-4'>
        <Row>
            <Col>
                <Button variant='link' onClick={() => history.goBack()}>
                    <span className='h6'>Back</span>
                </Button>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                <h1 className='text-primary text-center'>Exam Body List</h1>
            </Col>
        </Row>
        <Row>
            <Col sm={12} className='d-flex py-3 border-bottom'>
                <Image alt={newExamBody.name} src={newExamBody.logoUrl} thumbnail className='cursor-pointer' style={{ height: '100px' }} onClick={() => onClickExamBodyLogo(newExamBody)}/>
                <div className='ml-2 mt-auto' style={{ flex: 'auto' }}>
                    <label htmlFor={`fcontrol-${newExamBody.id}`}><b>Name</b></label>
                    <InputGroup>
                        <FormControl id={`fcontrol-${newExamBody.id}`} value={newExamBody.name} onChange={e => onChangeNewExamBody(e.target.value, 'name')} />
                    </InputGroup>
                </div>
                <div className='d-flex flex-column justify-content-end ml-2 '>
                    <Button variant='success' onClick={onCreateNewExamBody}>Create</Button>
                </div>
            </Col>
            {examBodies.map((examBody, ebi) => (<Col key={ebi} sm={12} md={6} className='d-flex py-3 border-bottom'>
                <Image alt={examBody.name} src={examBody.logoUrl} thumbnail className='cursor-pointer' style={{ height: '100px' }} onClick={() => onClickExamBodyLogo(examBody)}/>
                <div className='ml-2 mt-auto' style={{ flex: 'auto' }}>
                    <label htmlFor={`fcontrol-${examBody.id}`}><b>Name</b></label>
                    <InputGroup>
                        <FormControl id={`fcontrol-${examBody.id}`} value={examBody.name} onChange={e => onChangeExamBody(examBody, 'name', e.target.value)} />
                    </InputGroup>
                </div>
                <div className='d-flex flex-column justify-content-between ml-2 '>
                    <FontAwesomeIcon className='cursor-pointer text-danger ml-auto' size='lg' icon={faTimes} onClick={(e) => onRequestConfirmToRemove(e, examBody)} />
                    <Button onClick={() => onSubmitExamBody(examBody)}>Submit</Button>
                </div>
            </Col>))}
        </Row>
        <ConfirmPopover visible={confirmVisible} target={confirmTarget} placement='left' setVisibility={setConfirmVisible} label='Are you sure to delete Exam Body?' onConfirm={onDeleteExamBody} />
        <Form.File ref={fileInput} label='' className='d-none' onChange={onFileChanged} />
    </Container>);
};

AdminExamBodiesList.propTypes = {};
AdminExamBodiesList.defaultProps = {};

export default withRouter(connectToasts(AdminExamBodiesList));
