import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const topicsBaseURL = `${APIServerURL}/topics`;
const TopicsAPI =  {
    getAllTopics: () => {
        const url = `${topicsBaseURL}`;
        return axios.get(url);
    },
    getTopics: (subjectId) => {
        const url = `${topicsBaseURL}/${subjectId}`;
        return axios.get(url);
    },
    registerTopic: (subjectId, topic) => {
        const url = `${topicsBaseURL}/${subjectId}`;
        return axios.post(url, topic);
    },
    updateTopic: (topic) => {
        const url = `${topicsBaseURL}/${topic.id}`;
        return axios.put(url, { ...topic, id: null });
    },
    deleteTopic: (topicId) => {
        const url = `${topicsBaseURL}/${topicId}`;
        return axios.delete(url);
    }
}

export default TopicsAPI;
