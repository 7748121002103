import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import countryList from 'react-select-country-list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import ExamBobyOptionsAPI from '../../../common/api/exam-body-options';
import ExamGroupsAPI from '../../../common/api/exam-groups';
import ExamBodyAPI from '../../../common/api/exam-bodies';
import SubjectsAPI from '../../../common/api/subjects';
import { CustomSelect } from '../../../components/common/select';
import { connectToasts } from '../../../common/redux/connects';
import { ToastStatus } from '../../../common/enums/toast';
import { ConfirmPopover } from '../../../components/common/popover';
import { countryValueToLabel, countries } from '../../../common/utils/common';

const initExamBodyOption = {
    id: '',
    name: '',
    examGroup: { id: '' },
    examBody: { id: '' },
    subjects: [],
    country: ''
}

function AdminExamBodyOptionsList({ history, addToastAction }) {
    const [examGroups, setExamGroups] = useState([]);
    const [examBodies, setExamBodies] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [availableSubjects, setAvailableSubjects] = useState([]);
    const [loading, setLoading] = useState(true);

    const [visibleModal, setVisibleModal] = useState(false);

    const [examBodyOptions, setExamBodyOptions] = useState([]);
    const [curExamBodyOption, setCurExamBodyOption] = useState(initExamBodyOption);

    // Confirm Popover
    const [confirmTarget, setConfirmTarget] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [deletingExamBodyOptionId, setDeletingExamBodyOptionId] = useState(null);

    useEffect(() => {
        Promise.all([
            ExamGroupsAPI.getAllExamGroups(),
            ExamBodyAPI.getAllExamBodies(),
            SubjectsAPI.getAllSubjects(),
            ExamBobyOptionsAPI.getAllExamBodyOptions()
        ]).then(res => {
            setExamGroups(res[0]);
            setExamBodies(res[1]);
            setSubjects(res[2]);
            if (!res[0].length || !res[1].length || !res[2].length) {
                addToastAction('Warning', 'Need to register Exam Group, Exam Body, and Subject before to create these options.', ToastStatus.Info);
                history.goBack();
                return;
            }
            setExamBodyOptions(res[3]);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }, []);

    const initCurExamBodyOption = (arrExamGroups = null, arrExamBodies = null) => {
        const examGroup = arrExamGroups ? arrExamGroups[0] : examGroups[0] || {};
        const examBody = arrExamBodies ? arrExamBodies[0] : examBodies[0] || {};

        setCurExamBodyOption({
            ...initExamBodyOption,
            examGroup, examBody,
            subjects: [],
            country: countries[0].value || ''
        });
        setAvailableSubjects(subjects);
    }

    const onChangeCurExamBodyOption = (value, key) => {
        if (key === 'examGroup') {
            const examGroup = examGroups.find(eg => eg.id === value);
            setCurExamBodyOption({ ...curExamBodyOption, [key]: examGroup });
        } else if (key === 'examBody') {
            const examBody = examBodies.find(eb => eb.id === value);
            setCurExamBodyOption({ ...curExamBodyOption, [key]: examBody });
        } else {
            setCurExamBodyOption({ ...curExamBodyOption, [key]: value });
        }
    }

    const onSubmitExamBodyOption = () => {
        if (!curExamBodyOption.id) {
            // Register
            setLoading(true);
            const registerDto = {
                ...curExamBodyOption,
                examGroup: curExamBodyOption.examGroup.id,
                examBody: curExamBodyOption.examBody.id,
                subjects: curExamBodyOption.subjects.map(s => s.id)
            }
            ExamBobyOptionsAPI.registerExamBodyOption(registerDto).then(res => {
                setExamBodyOptions(examBodyOptions.concat([{ ...curExamBodyOption, id: res.id }]));
                setLoading(false);
                addToastAction('Success', 'You have successfully created a new exam body option.', ToastStatus.Success);
            }, e => {
                setLoading(false);
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
            
        } else {
            // Update
            setLoading(true);
            const updateDto = {
                ...curExamBodyOption,
                examGroup: curExamBodyOption.examGroup.id,
                examBody: curExamBodyOption.examBody.id,
                subjects: curExamBodyOption.subjects.map(s => s.id)
            }
            ExamBobyOptionsAPI.updateExamBodyOption(updateDto).then(res => {
                setLoading(false);
                setExamBodyOptions(examBodyOptions.map(option => option.id === curExamBodyOption.id ? curExamBodyOption: option));
                addToastAction('Success', 'You have successfully updated exam body option.', ToastStatus.Success);
            }, e => {
                setLoading(false);
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
        setAvailableSubjects(subjects);
        initCurExamBodyOption();
        setVisibleModal(false);
    }

    const onAddNewSubject = (subject) => {
        setCurExamBodyOption({ ...curExamBodyOption, subjects: curExamBodyOption.subjects.concat([subject]).sort((f, l) => l.name > f.name ? -1 : 1) });
        setAvailableSubjects(availableSubjects.filter(s => s.id !== subject.id).sort((f, l) => l.name > f.name ? -1 : 1));
    };

    const onRemoveSubject = (subject) => {
        setCurExamBodyOption({ ...curExamBodyOption, subjects: curExamBodyOption.subjects.filter(s => s.id !== subject.id).sort((f, l) => l.name > f.name ? -1 : 1) });
        setAvailableSubjects(availableSubjects.concat([subject]).sort((f, l) => l.name > f.name ? -1 : 1));
    };

    const onClickCreateExamBodyOption = () => {
        initCurExamBodyOption();
        setVisibleModal(true);
    };

    const onClickExamBodyOptionUpdate = (option) => {
        const enabledSubjectIdList = option.subjects.map(s => s.id);
        setCurExamBodyOption(option);
        setAvailableSubjects(subjects.filter(s => !enabledSubjectIdList.includes(s.id)));
        setVisibleModal(true);
    };

    const onClickRemoveExamBodyOption = (event, option) => {
        setConfirmTarget(event.target);
        setDeletingExamBodyOptionId(option.id);
        setConfirmVisible(true);
    }

    const onDeleteExamBodyOption = () => {
        setLoading(true);
        ExamBobyOptionsAPI.deleteExamBodyOption(deletingExamBodyOptionId).then(res => {
            setExamBodyOptions(examBodyOptions.filter(option => option.id !== deletingExamBodyOptionId));
            setDeletingExamBodyOptionId(null);
            setLoading(false);
            addToastAction('Success', 'You have successfully removed a exam body option.', ToastStatus.Success);
        }, e => {
            setDeletingExamBodyOptionId(null);
            setLoading(false);
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
        setConfirmVisible(false);
    }

    return (<Container className='pt-4'>
        <Row>
            <Col>
                <Button variant='link' onClick={() => history.goBack()}>
                    <span className='h6'>Back</span>
                </Button>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                <h1 className='text-primary text-center'>Exam Body Options List</h1>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Button className='float-right' variant='success' onClick={onClickCreateExamBodyOption}>Create a new Option</Button>
            </Col>
        </Row>
        <hr />
        <Row>
            {examBodyOptions.map((option, oi) => <Col key={oi} sm={12} lg={4} className='mb-2'>
                <Card>
                    <Card.Body>
                        <Card.Title>{option.name}</Card.Title>
                        <Card.Subtitle className="my-2 text-muted">
                            <FontAwesomeIcon size='lg' icon={faMapMarkerAlt} />&nbsp;{countryValueToLabel(option.country)}
                        </Card.Subtitle>
                        <Card.Text>
                            <b>Exam Group:</b> {option.examGroup.name}<br/>
                            <b>Exam Body:</b> {option.examBody.name}<br/>
                            <b>Subjects:</b> {option.subjects.map(s => s.name).join(', ')}
                        </Card.Text>
                        <Button variant='primary' className='ml-2 float-right' onClick={() => onClickExamBodyOptionUpdate(option)}>Update</Button>
                        <Button variant='danger' className='float-right' onClick={(e) => onClickRemoveExamBodyOption(e, option)}>Remove</Button>
                    </Card.Body>
                </Card>
            </Col>)}

        </Row>
        <Modal show={visibleModal} onHide={() => setVisibleModal(false)} backdrop='static' size='lg'
            keyboard={false} aria-labelledby='contained-modal-title-vcenter' centered className='px-0'>
            <Modal.Header closeButton>
                <Modal.Title>{!curExamBodyOption.id ? 'Create a new' : 'Update'} Exam Body Option</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label><b>Name</b></Form.Label>
                            <Form.Control placeholder='Enter new Exam Body Option name' value={curExamBodyOption.name} onChange={e => onChangeCurExamBodyOption(e.target.value, 'name')} />
                        </Form.Group>
                        <CustomSelect label='Country' values={countries} value={curExamBodyOption.country} onChange={e => onChangeCurExamBodyOption(e.target.value, 'country')} />
                        <CustomSelect label='Exam Group' values={examGroups} value={curExamBodyOption.examGroup.id} onChange={e => onChangeCurExamBodyOption(e.target.value, 'examGroup')} />
                        <CustomSelect label='Exam Body' values={examBodies} value={curExamBodyOption.examBody.id} onChange={e => onChangeCurExamBodyOption(e.target.value, 'examBody')} />
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label><b>Available Subjects</b></Form.Label>
                            <ListGroup>
                                {availableSubjects.map((subject, si) => <ListGroup.Item key={si} action onClick={() => onAddNewSubject(subject)}>
                                    {subject.name}
                                </ListGroup.Item>)}
                            </ListGroup>
                        </Form.Group>
                    </Col>
                    <Col sm={12} lg={6}>
                        <Form.Group>
                            <Form.Label><b>Enabled Subjects</b></Form.Label>
                            <ListGroup>
                                {curExamBodyOption.subjects.map((subject, si) => <ListGroup.Item key={si} action onClick={() => onRemoveSubject(subject)}>
                                    {subject.name}
                                </ListGroup.Item>)}
                            </ListGroup>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => setVisibleModal(false)}>Close</Button>
                <Button className='float-right' variant='success' onClick={onSubmitExamBodyOption}>Submit</Button>
            </Modal.Footer>
        </Modal>
        <ConfirmPopover visible={confirmVisible} target={confirmTarget} placement='left' setVisibility={setConfirmVisible}
            label='Are you sure to delete Exam Body Option?' onConfirm={onDeleteExamBodyOption} />
    </Container>);
};

AdminExamBodyOptionsList.propTypes = {};
AdminExamBodyOptionsList.defaultProps = {};

export default withRouter(connectToasts(AdminExamBodyOptionsList));
