import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { ConfirmPopover } from '../../../components/common/popover';
import { CustomSelect } from '../../../components/common/select';
import { connectToasts } from '../../../common/redux/connects';
import { ToastStatus } from '../../../common/enums/toast';
import SubjectsAPI from '../../../common/api/subjects';
import TopicsAPI from '../../../common/api/topics';
import SubTopicsAPI from '../../../common/api/sub-topics';

const initSubject = { name: '', code: '' };
const initTopic = { name: '' };
const initSubTopic = { name: '' };


function AdminSubjectsList({ history, addToastAction }) {
    // Subject Variables
    const [subjects, setSubjects] = useState([]);
    const [newSubject, setNewSubject] = useState(initSubject);

    // Topics Variables
    const [curSubjectId, setCurSubjectId] = useState('');
    const [topics, setTopics] = useState([]);
    const [newTopic, setNewTopic] = useState(initTopic)

    // SubTopics Variables
    const [curTopicId, setCurTopicId] = useState('');
    const [subTopics, setSubTopics] = useState([]);
    const [newSubTopic, setNewSubTopic] = useState(initSubTopic);

    // Confirm Popover
    const [confirmSubjectTarget, setConfirmSubjectTarget] = useState(null);
    const [confirmSubjectVisible, setConfirmSubjectVisible] = useState(false);
    const [confirmTopicTarget, setConfirmTopicTarget] = useState(null);
    const [confirmTopicVisible, setConfirmTopicVisible] = useState(false);
    const [confirmSubTopicTarget, setConfirmSubTopicTarget] = useState(null);
    const [confirmSubTopicVisible, setConfirmSubTopicVisible] = useState(false);
    const [objectIdToRemove, setObjectIdToRemove] = useState(null);

    const [loading, setLoading] = useState(false);

    // Life Cycles
    useEffect(() => { getAllSubjects(); }, []);
    useEffect(() => { getTopicsBySubject() }, [curSubjectId]);
    useEffect(() => { getSubTopicsByTopic() }, [curTopicId]);


    /* Member Functions */
    // Subjects
    const getAllSubjects = () => {
        setLoading(true);
        SubjectsAPI.getAllSubjects().then(arrSubjects => {
            setSubjects(arrSubjects);
            setCurSubjectId(arrSubjects[0].id);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onChangeNewSubject = (value, key) => {
        setNewSubject({ ...newSubject, [key]: value });
    };
    const onChangeSubject = (curSubject, value, key) => {
        setSubjects(subjects.map(subject => {
            if (subject.id === curSubject.id) {
                return { ...subject, [key]: value };
            } else {
                return subject;
            }
        }))
    };
    const onRegisterSubject = () => {
        setLoading(true);
        SubjectsAPI.registerSubject(newSubject).then(res => {
            addToastAction(`Congratulation`, 'You have successfully registered a new subject.', ToastStatus.Success);
            setLoading(false);
            setSubjects(subjects.concat([{ ...newSubject, id: res.id }]));
            setNewSubject(initSubject);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onUpdateSubject = (subject) => {
        setLoading(true);
        SubjectsAPI.updateSubject(subject).then(res => {
            addToastAction(`Congratulation`, 'You have successfully updated subject.', ToastStatus.Success);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onDeleteSubject = () => {
        setConfirmSubjectVisible(false);
        setLoading(true);
        SubjectsAPI.deleteSubject(objectIdToRemove).then(res => {
            addToastAction(`Congratulation`, 'You have successfully removed a subject.', ToastStatus.Success);
            setLoading(false);
            setSubjects(subjects.filter(subject => subject.id !== objectIdToRemove));
            setObjectIdToRemove(null);
            setCurSubjectId(subjects[0] ? subjects[0].id : '');
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onClickRemoveSubject = (event, subjectId) => {
        setObjectIdToRemove(subjectId);
        setConfirmSubjectTarget(event.target);
        setConfirmSubjectVisible(true);
    };

    // Topics
    const getTopicsBySubject = () => {
        if (!curSubjectId) {
            setTopics([]);
            return;
        }
        setLoading(true);
        TopicsAPI.getTopics(curSubjectId).then(arrTopics => {
            setTopics(arrTopics);
            setCurTopicId(arrTopics[0] ? arrTopics[0].id : '');
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onRegisterTopic = () => {
        setLoading(true);
        TopicsAPI.registerTopic(curSubjectId, newTopic).then(res => {
            addToastAction(`Congratulation`, 'You have successfully registered a new topic.', ToastStatus.Success);
            setLoading(false);
            setTopics(topics.concat([{ ...newTopic, id: res.id }]));
            setNewTopic(initTopic);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onUpdateTopic = (topic) => {
        setLoading(true);
        TopicsAPI.updateTopic(topic).then(res => {
            addToastAction(`Congratulation`, 'You have successfully updated a topic.', ToastStatus.Success);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    }
    const onDeleteTopic = () => {
        setConfirmTopicVisible(false);
        setLoading(true);
        TopicsAPI.deleteTopic(objectIdToRemove).then(res => {
            addToastAction(`Congratulation`, 'You have successfully removed a topic.', ToastStatus.Success);
            setLoading(false);
            setTopics(topics.filter(topic => topic.id !== objectIdToRemove));
            setObjectIdToRemove(null);
            setCurTopicId(topics[0] ? topics[0].id : '');
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onChangeNewTopic = (value, key) => {
        setNewTopic({ ...newTopic, [key]: value });
    };
    const onChangeTopic = (curTopic, value, key) => {
        setTopics(topics.map(topic => {
            if (topic.id === curTopic.id) {
                return { ...topic, [key]: value };
            } else {
                return topic;
            }
        }));
    }
    const onClickRemoveTopic = (event, topicId) => {
        setObjectIdToRemove(topicId);
        setConfirmTopicTarget(event.target);
        setConfirmTopicVisible(true);
    }

    // SubTopics
    const getSubTopicsByTopic = () => {
        if (!curTopicId) {
            setSubTopics([]);
            return;
        }
        setLoading(true);
        SubTopicsAPI.getSubTopics(curTopicId).then(arrSubTopics => {
            setSubTopics(arrSubTopics);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onRegisterSubTopic = () => {
        setLoading(true);
        SubTopicsAPI.registerSubTopic(curTopicId, newSubTopic).then(res => {
            addToastAction(`Congratulation`, 'You have successfully registered a new sub topic.', ToastStatus.Success);
            setLoading(false);
            setSubTopics(subTopics.concat([{ ...newSubTopic, id: res.id }]));
            setNewSubTopic(initSubTopic);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onUpdateSubTopic = (subTopic) => {
        setLoading(true);
        SubTopicsAPI.updateSubTopic(subTopic).then(res => {
            addToastAction(`Congratulation`, 'You have successfully updated a sub topic.', ToastStatus.Success);
            setLoading(false);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onDeleteSubTopic = () => {
        setConfirmSubTopicVisible(false);
        setLoading(true);
        SubTopicsAPI.deleteSubTopic(objectIdToRemove).then(res => {
            addToastAction(`Congratulation`, 'You have successfully removed a sub topic.', ToastStatus.Success);
            setLoading(false);
            setSubTopics(subTopics.filter(subTopic => subTopic.id !== objectIdToRemove));
            setObjectIdToRemove(null);
        }, e => {
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            setLoading(false);
        });
    };
    const onChangeNewSubTopic = (value, key) => {
        setNewSubTopic({ ...newSubTopic, [key]: value });
    };
    const onChangeSubTopic = (curSubTopic, value, key) => {
        setSubTopics(subTopics.map(subTopic => {
            if (subTopic.id === curSubTopic.id) {
                return { ...subTopic, [key]: value };
            } else {
                return subTopic;
            }
        }));
    };
    const onClickRemoveSubTopic = (event, subTopicId) => {
        setObjectIdToRemove(subTopicId);
        setConfirmSubTopicTarget(event.target);
        setConfirmSubTopicVisible(true);
    }

    return (<Container className='pt-4'>
        <Row>
            <Col>
                <Button variant='link' onClick={() => history.goBack()}>
                    <span className='h6'>Back</span>
                </Button>
            </Col>
        </Row>
        <Row className='mb-5'>
            <Col>
                <h1 className='text-primary text-center'>Subjects & Topic & SubTopics</h1>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <Tabs defaultActiveKey='SUBJECTS'>
                    <Tab eventKey='SUBJECTS' title='Subject'>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <Row className='mb-3'>
                                    <Col md={6} className='mb-1'>
                                        <InputGroup className='align-items-center'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id={`name-new-subject`}>Name</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl aria-label="Default" aria-describedby={`name-new-subject`} value={newSubject.name} onChange={e => onChangeNewSubject(e.target.value, 'name')} />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} className='mb-1'>
                                        <InputGroup className='align-items-center'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id={`code-new-subject`}>Code</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl aria-label="Default" aria-describedby={`code-new-subject`} value={newSubject.code} onChange={e => onChangeNewSubject(e.target.value, 'code')} />
                                            <Button className='ml-2' variant='success' onClick={onRegisterSubject}>Create</Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            {subjects.map((subject, si) => <ListGroup.Item key={si}>
                                <InputGroup className='align-items-center'>
                                    <h5>{si + 1}.&nbsp;&nbsp;</h5>
                                    <Row className='flex-auto'>
                                        <Col md={6} className='mb-1 d-flex'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id={`name-${subject.id}`}>Name</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl aria-label="Default" aria-describedby={`name-${subject.id}`} value={subject.name} onChange={e => onChangeSubject(subject, e.target.value, 'name')} />
                                        </Col>
                                        <Col md={6} className='mb-1 d-flex'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id={`code-${subject.id}`}>Code</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl aria-label="Default" aria-describedby={`code-${subject.id}`} value={subject.code} onChange={e => onChangeSubject(subject, e.target.value, 'code')} />
                                        </Col>
                                        <Col sm={12}>
                                            <Button className='float-right ml-2' onClick={() => onUpdateSubject(subject)}>Update</Button>
                                            <Button className='float-right' variant='danger' onClick={e => onClickRemoveSubject(e, subject.id)}>Remove</Button>
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Tab>
                    <Tab eventKey='TOPICS' title='Topics'>
                        <Row className='mt-3 px-3'>
                            <Col sm={12}>
                                <CustomSelect label='Subject' values={subjects} value={curSubjectId} onChange={e => setCurSubjectId(e.target.value)} />
                            </Col>
                        </Row>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='mb-3'>
                                <InputGroup className='align-items-center'>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={`name-new-topic`}>Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="Default" aria-describedby={`name-new-topic`} value={newTopic.name} onChange={e => onChangeNewTopic(e.target.value, 'name')} />
                                    <Button className='ml-2' variant='success' onClick={onRegisterTopic}>Create</Button>
                                </InputGroup>
                            </ListGroup.Item>
                            {topics.map((topic, ti) => <ListGroup.Item key={ti}>
                                <InputGroup className='align-items-center'>
                                    <span><b>{ti + 1}.</b>&nbsp;&nbsp;</span>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={`name-${topic.id}`}>Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="Default" aria-describedby={`name-${topic.id}`} value={topic.name} onChange={e => onChangeTopic(topic, e.target.value, 'name')} />
                                    <Button className='ml-2' variant='danger' onClick={e => onClickRemoveTopic(e, topic.id)}>Remove</Button>
                                    <Button className='ml-2' onClick={() => onUpdateTopic(topic)}>Update</Button>
                                </InputGroup>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Tab>
                    <Tab eventKey='SUB-TOPICS' title='Sub Topics'>
                        <Row className='mt-3 px-3'>
                            <Col sm={12} lg={6}>
                                <CustomSelect label='Subject' values={subjects} value={curSubjectId} onChange={e => setCurSubjectId(e.target.value)} />
                            </Col>
                            <Col sm={12} lg={6}>
                                <CustomSelect label='Topic' values={topics} value={curTopicId} onChange={e => setCurTopicId(e.target.value)} />
                            </Col>
                        </Row>
                        <ListGroup variant='flush'>
                            <ListGroup.Item className='mb-3'>
                                <InputGroup className='align-items-center'>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={`name-new-sub-topic`}>Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="Default" aria-describedby={`name-new-sub-topic`} value={newSubTopic.name} onChange={e => onChangeNewSubTopic(e.target.value, 'name')} />
                                    <Button className='ml-2' variant='success' onClick={onRegisterSubTopic}>Create</Button>
                                </InputGroup>
                            </ListGroup.Item>
                            {subTopics.map((subTopic, ti) => <ListGroup.Item key={ti}>
                                <InputGroup className='align-items-center'>
                                    <span><b>{ti + 1}.</b>&nbsp;&nbsp;</span>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id={`name-${subTopic.id}`}>Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl aria-label="Default" aria-describedby={`name-${subTopic.id}`} value={subTopic.name} onChange={e => onChangeSubTopic(subTopic, e.target.value, 'name')} />
                                    <Button className='ml-2' variant='danger' onClick={e => onClickRemoveSubTopic(e, subTopic.id)}>Remove</Button>
                                    <Button className='ml-2' onClick={() => onUpdateSubTopic(subTopic)}>Update</Button>
                                </InputGroup>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
        <ConfirmPopover visible={confirmSubjectVisible} target={confirmSubjectTarget} placement='left'
            setVisibility={setConfirmSubjectVisible} label='Are you sure to delete this subject?' onConfirm={onDeleteSubject} />
        <ConfirmPopover visible={confirmTopicVisible} target={confirmTopicTarget} placement='left'
            setVisibility={setConfirmTopicVisible} label='Are you sure to delete this topic?' onConfirm={onDeleteTopic} />
        <ConfirmPopover visible={confirmSubTopicVisible} target={confirmSubTopicTarget} placement='left'
            setVisibility={setConfirmSubTopicVisible} label='Are you sure to delete this sub topic?' onConfirm={onDeleteSubTopic} />
    </Container>);
};

AdminSubjectsList.propTypes = {};
AdminSubjectsList.defaultProps = {};

export default withRouter(connectToasts(AdminSubjectsList));
