/* AUTHENTICATION */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const VERIFY_ACCESS_TOKEN = 'VERIFY-ACCESS-TOKEN';
export const VERIFY_ACCESS_TOKEN_SUCCESS = 'VERIFY-ACCESS-TOKEN-SUCCESS';
export const VERIFY_ACCESS_TOKEN_ERROR = 'VERIFY-ACCESS-TOKEN-ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// Toast
export const TOAST_ADD = 'TOAST-ADD';
export const TOAST_REMOVE = 'TOAST-REMOVE';
export const TOAST_REMOVE_ALL = 'TOAST-REMOVE-ALL';
