import React, { useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    Link
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

// Pages
import AdminDashboard from '../pages/admin/dashboard';
import AdminExamBodyOptionsList from '../pages/admin/exam-body-options/exam-body-options-list';

import AdminExamGroupsList from '../pages/admin/exam-groups/exam-groups-list';
import AdminExamBodiesList from '../pages/admin/exam-bodies/exam-bodies-list';
import AdminSubjectsList from '../pages/admin/subjects/subjects-list';

import AdminSelectExamBody from '../pages/admin/questions/select-exam-body';
import AdminQuestionGroups from '../pages/admin/questions/question-groups';
import AdminQuestionsAdd from '../pages/admin/questions/question-add';
import AdminQuestionsEdit from '../pages/admin/questions/question-edit';
import AdminQuestionsList from '../pages/admin/questions/question-list';

// Components
import { CustomSwitch } from '../components/common/switch';

// Utils
import { connectAuth } from '../common/redux/connects';
import { isDarkTheme, toggleDarkTheme } from '../common/utils/theme';
import { UserRole } from '../common/enums/auth';

function AdminLayout({ match, history, user, logoutUserAction }) {
    const [darkTheme] = useState(isDarkTheme());

    return (<div className='vh-100 vw-100'>
        <Navbar className='shadow-sm bg-white' expand='sm'>
            <Navbar.Brand>
                <span className='cursor-pointer' onClick={() => history.push('/')}>Question Builder</span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className='justify-content-end'>
                <Nav className='ml-auto mr-3'>
                    <div className='d-flex'>
                        <CustomSwitch className='my-auto mr-1' value={darkTheme} onChange={e => toggleDarkTheme(e.target.checked)} />
                        <Nav.Link disabled className='px-0'>Dark Theme</Nav.Link>
                    </div>
                    <NavDropdown alignRight title={<FontAwesomeIcon className='mx-1 my-auto' icon={faUser} size='lg' />}>
                        <NavDropdown.Item onClick={() => history.push(`${match.url}/dashboard`)}>Dashboard</NavDropdown.Item>
                        <NavDropdown.Item disabled={user.role !== UserRole.SuperAdmin} onClick={() => history.push(`${match.url}/exam-body-options`)}>Exam Body Options</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => history.push(`${match.url}/questions/select-exam-body`)}>Questions</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled={user.role !== UserRole.SuperAdmin} onClick={() => history.push(`${match.url}/exam-groups`)}>Exam Groups</NavDropdown.Item>
                        <NavDropdown.Item disabled={user.role !== UserRole.SuperAdmin} onClick={() => history.push(`${match.url}/exam-bodies`)}>Exam Bodies</NavDropdown.Item>
                        <NavDropdown.Item disabled={user.role !== UserRole.SuperAdmin} onClick={() => history.push(`${match.url}/subjects`)}>Subjects</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item disabled>Profile</NavDropdown.Item>
                        <NavDropdown.Item disabled>Notifications</NavDropdown.Item>
                        <NavDropdown.Item onClick={logoutUserAction}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <div className='mx-2'>
            <main className='container mt-3 shadow-sm rounded bg-white p-3'>
                <Switch>
                    <Route exact path={`${match.url}/dashboard`} component={AdminDashboard} />
                    <Route exact path={`${match.url}/exam-body-options`} component={AdminExamBodyOptionsList} />

                    <Route exact path={`${match.url}/exam-groups`} component={AdminExamGroupsList} />
                    <Route exact path={`${match.url}/exam-bodies`} component={AdminExamBodiesList} />
                    <Route exact path={`${match.url}/subjects`} component={AdminSubjectsList} />

                    <Route exact path={`${match.url}/questions/select-exam-body`} component={AdminSelectExamBody} />
                    <Route exact path={`${match.url}/questions/by-exam-body/:examBodyOptionId`} component={AdminQuestionGroups} />
                    <Route exact path={`${match.url}/questions/add/:examBodyOptionId/:subjectId/:year`} component={AdminQuestionsAdd} />
                    <Route exact path={`${match.url}/questions/view/:examBodyOptionId/:subjectId/:year`} component={AdminQuestionsList} />
                    <Route exact path={`${match.url}/questions/edit/:questionId`} component={AdminQuestionsEdit} />
                    <Route path='/' >
                        <Redirect to={`${match.url}/questions/select-exam-body`} />
                    </Route>
                </Switch>
            </main>
        </div>
    </div>);
};

export default withRouter(connectAuth(AdminLayout));