import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const subjectsBaseURL = `${APIServerURL}/subjects`;
const SubjectsAPI =  {
    getAllSubjects: () => {
        const url = `${subjectsBaseURL}`;
        return axios.get(url);
    },
    registerSubject: (subject) => {
        const url = `${subjectsBaseURL}`;
        return axios.post(url, subject);
    },
    updateSubject: (subject) => {
        const url = `${subjectsBaseURL}/${subject.id}`;
        return axios.put(url, subject);
    },
    deleteSubject: (subjectId) => {
        const url = `${subjectsBaseURL}/${subjectId}`;
        return axios.delete(url);
    }
}

export default SubjectsAPI;
