import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const examBodyOptionsBaseURL = `${APIServerURL}/exam-body-options`;
const ExamBobyOptionsAPI =  {
    getAllExamBodyOptions: () => {
        const url = `${examBodyOptionsBaseURL}`;
        return axios.get(url);
    },
    getExamBodyOptionById: (examBodyOptionId) => {
        const url = `${examBodyOptionsBaseURL}/${examBodyOptionId}`;
        return axios.get(url);
    },
    registerExamBodyOption: (examBodyOption) => {
        const url = `${examBodyOptionsBaseURL}`;
        return axios.post(url, examBodyOption);
    },
    updateExamBodyOption: (examBodyOption) => {
        const url = `${examBodyOptionsBaseURL}/${examBodyOption.id}`;
        return axios.put(url, { ...examBodyOption, id: null });
    },
    deleteExamBodyOption: (examBodyOptionId) => {
        const url = `${examBodyOptionsBaseURL}/${examBodyOptionId}`;
        return axios.delete(url);
    }
}

export default ExamBobyOptionsAPI;
