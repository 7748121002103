import countryList from 'react-select-country-list';

export const capitalizeSentence = (sentence, forEachWord = false) => {
    if (forEachWord) {
        return sentence.split(' ').map(eachWord => capitalizeWord(eachWord)).join(' ');
    } else {
        return capitalizeWord(sentence);
    }
};

export const capitalizeWord = (word) => {
    return String(word[0]).toUpperCase() + String(word.substring(1)).toLowerCase();
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const countries = countryList().getData();

export const countryValueToLabel = (value) => {
    const country = countries.find(c => c.value === value);
    return country ? country.label : value;
}