import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { MathComponent } from 'mathjax-react';

// const defaultTex = `\\begin{array}{l}\\frac{1}{x}\\end{array}`;

export const AnyTextInput = ({ value, onChange, className, editable, equational }) => {
    const onError = (error) => { console.log(error); };
    const onSuccess = () => { };

    const renderEditableArea = () => {
        if (editable) {
            return <Form.Control as='textarea' value={value} onChange={e => onChange(e.target.value)} />;
        } else if (!equational) {
            return <span>{value}</span>;
        }
    };

    return (<Fragment>
        {renderEditableArea()}
        {!equational ? null :  <MathComponent tex={value} onError={onError} onSuccess={onSuccess} />}
    </Fragment>
    );
};
AnyTextInput.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    equational: PropTypes.bool
};
AnyTextInput.defaultProps = {
    className: '',
    value: '',
    onChange: () => { },
    editable: false,
    equational: false
};

export const AnyInputGroup = ({ value, onChange, className, editable, equational, prepend, append }) => {
    const onError = (error) => { console.log(error); };
    const onSuccess = () => { };

    return (<InputGroup className='mb-3'>
        {!prepend ? null : <InputGroup.Prepend><InputGroup.Text>{prepend}</InputGroup.Text></InputGroup.Prepend>}
        <div className='d-flex flex-fill flex-column overflow-hidden'>
            <FormControl disabled={!editable} value={value} onChange={e => onChange(e.target.value)} />
            {!equational ? null : <MathComponent tex={value} onError={onError} onSuccess={onSuccess} />}
        </div>
        {!append ? null : <InputGroup.Append><InputGroup.Text>{append}</InputGroup.Text></InputGroup.Append>}
    </InputGroup>);
};
AnyInputGroup.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    equational: PropTypes.bool,
    prepend: PropTypes.string,
    append: PropTypes.string,
};
AnyInputGroup.defaultProps = {
    className: '',
    value: '',
    onChange: () => { },
    editable: false,
    equational: false,
    prepend: '',
    append: ''
};

