import axios from 'axios';

// Constants
import { APIServerURL } from '../constants/default-values';

const examGroupsBaseURL = `${APIServerURL}/exam-groups`;
const ExamGroupsAPI =  {
    getAllExamGroups: () => {
        const url = `${examGroupsBaseURL}`;
        return axios.get(url);
    },
    getExamGroupById: (examGroupId) => {
        const url = `${examGroupsBaseURL}/by-id/${examGroupId}`;
        return axios.get(url);
    },
    createExamGroup: (examGroup) => {
        const url = `${examGroupsBaseURL}`;
        return axios.post(url, examGroup);
    },
    updateExamGroup: (examGroup) => {
        const url = `${examGroupsBaseURL}/${examGroup.id}`;
        return axios.put(url, { ...examGroup, id: null });
    },
    deleteExamGroup: (examGroupId) => {
        const url = `${examGroupsBaseURL}/${examGroupId}`;
        return axios.delete(url);
    }
}

export default ExamGroupsAPI;
