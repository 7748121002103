import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { LoadingSpinner } from '../../../components/common/spinner';

import ExamBodyOptionsAPI from '../../../common/api/exam-body-options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { countryValueToLabel } from '../../../common/utils/common';

function AdminSelectExamBody({ history }) {
    const [examBodyOptions, setExamBodyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        ExamBodyOptionsAPI.getAllExamBodyOptions().then(options => {
            setExamBodyOptions(options);
            setIsLoading(false);
        }, e => {
            console.log(e);
            setIsLoading(false);
        });
    }, [])

    return (<Container className='pt-4'>
        <Row className='mb-3'>
            <Col>
                <h1 className='text-primary text-center'>
                    Select Exam Body to start
                </h1>
            </Col>
        </Row>
        {isLoading ? <LoadingSpinner /> : null}
        <Row>
            {examBodyOptions.map((option, oi) =>
                <Col key={oi} md={6} lg={4} className='p-1'>
                    <Card className='w-100 h-100 shadow-sm'>
                        <Card.Body className='d-flex flex-column justify-content-between'>
                            <Card.Title className='text-center d-flex align-items-center'>
                                <Card.Img className='w-4rem my-2 mr-3' variant='top' src={option.examBody.logoUrl} />
                                <h3>{option.name}</h3>
                            </Card.Title>
                            <Card.Subtitle className="my-2 text-muted">
                                <FontAwesomeIcon size='lg' icon={faMapMarkerAlt} />&nbsp;{countryValueToLabel(option.country)}
                            </Card.Subtitle>
                            <Card.Text>
                                <b>Exam Group:</b> {option.examGroup.name}<br />
                                <b>Exam Body:</b> {option.examBody.name}<br />
                                <b>Subjects:</b> {option.subjects.map(s => s.name).join(', ')}
                            </Card.Text>
                            <Button className='float-right' variant='primary'
                                onClick={() => history.push(`/admin/questions/by-exam-body/${option.id}`)}>Go</Button>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Row>
    </Container>);
};

AdminSelectExamBody.propTypes = {};
AdminSelectExamBody.defaultProps = {};

export default withRouter(AdminSelectExamBody);
