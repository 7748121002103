import React from 'react';

function AdminDashboard() {
    return (
        <div className='text-center'>
            <h1>Admin Dashboard</h1>
        </div>
    );
};

AdminDashboard.propTypes = {};
AdminDashboard.defaultProps = {};

export default AdminDashboard;
