import React from 'react';

function CustomerDashboard() {

    return (
        <div className='bg-primary py-100 text-center'>
            <span className='display-1'>Customer Dashboard</span>
        </div>
    );
};

CustomerDashboard.propTypes = {};
CustomerDashboard.defaultProps = {};

export default CustomerDashboard;
