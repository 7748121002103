export const UserRole = {
    SuperAdmin: 'SUPER-ADMIN',
    Admin: 'ADMIN',
    Customer: 'CUSTOMER'
};

export const UserGender = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER',
};
